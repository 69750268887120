import React, { useState } from "react"
import styled from "styled-components"

const FAQAccordion = props => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  const FAQgrid = styled.div`
    display: grid;
    grid-template-columns: 80% 15%;
    column-gap: 5%;
    border-bottom: ${accordionOpen ? `none` : `1px solid #000`};
    border-top: ${accordionOpen ? `1px solid #000` : `none`};
    border-left: ${accordionOpen ? `1px solid #000` : `none`};
    border-right: ${accordionOpen ? `1px solid #000` : `none`};
    align-items: center;
    background-color: #efefef;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 95% 5%;
      column-gap: 0;
    }
  `

  const FAQquestion = styled.p`
    font-style: italic;
    padding-left: 10px;
    margin: ${accordionOpen ? `10px` : `10px 0`};
    &:hover {
      cursor: pointer;
    }
  `

  const FAQplus = styled.span`
    display: ${accordionOpen ? `none` : `inline`};
    &:hover {
      cursor: pointer;
    }
  `

  const Openwrapper = styled.span`
    &:hover {
      cursor: pointer;
    }
  `

  const FAQopen = styled.div`
    display: ${accordionOpen ? `flex` : `none`};
    border-radius: 50%;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: 15px;
    margin-top: 10px;
    font-weight: 700;
  `

  const FAQanswer = styled.div`
    display: ${accordionOpen ? `block` : `none`};
    background-color: #ffffff;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    padding: 10px 10px 20px 10px;
  `

  return (
    <div>
      <FAQgrid onClick={() => setAccordionOpen(!accordionOpen)}>
        <FAQquestion>{props.question}</FAQquestion>

        <FAQplus>+</FAQplus>
        <Openwrapper>
          <FAQopen>
            <span>-</span>
          </FAQopen>
        </Openwrapper>
      </FAQgrid>
      <FAQanswer>{props.answer}</FAQanswer>
    </div>
  )
}
export { FAQAccordion }
