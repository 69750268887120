import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Sidebar from "./sidebar"
import GreenHeading from "../heading"
import device from "../device"
import useLinks, { ReserveNow } from "../hooks/useLinks"
import { FAQAccordion } from "../FAQAccordion"
import { Helmet } from "react-helmet"
import { transformURLs } from "../utils/transformURLs"

const FAQWrapper = styled.div`
  background-color: #efefef;
  border: 1px solid #000;
  padding: 20px 30px;
`

const FAQHeader = styled.p`
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0.75em 0 !important;
  line-height: 30px;
  @media only screen and (max-width: 1024px) {
    font-size: calc(26px + 24 * ((100vw - 768px) / 432)) !important;
    line-height: calc(30px + 30 * ((100vw - 768px) / 432)) !important;
  }
  @media only screen and (max-width: 2000px) {
    font-size: 50px !important;
    line-height: 60px !important;
  }
`

const OuterWrapper = styled.div`
  margin-bottom: ${props =>
    props.sectionName === "our-services" ? "6rem" : "0rem"};
  .bg {
    margin-top: -40%;
    align-self: end;
    position: relative;
    z-index: -5;
  }
`

const Wrapper = styled.section`
  max-width: var(--limit);
  display: flex;
  margin: 0 auto;
  ${device.large`margin-top: -10rem;`}
  ${device.small`flex-direction: column;`}
  .left {
    flex: 1;
    margin-top: -5rem;
    ${device.large`margin-top: 0;`}
  }
  .right {
  }
  .mid-section {
    margin: 4rem 0;
    width: 85%;
    max-width: 1500px;
    position: relative;
    ${device.small`width: 100%;`}
  }
  .image-wrapper {
    position: relative;
    width: 85%;
    ${device.small`width: 100%; height: 40vh;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    `}
    .buttons {
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      position: absolute;
      left: 50%;
      bottom: 5rem;
      transform: translate(-50%, 0);
      font-size: 0.8rem;
      ${device.small`flex-direction: column;bottom: 0;`}
      .call-today {
        margin-left: 4rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: var(--white);
        font-size: 0.9rem;
        ${device.small`margin: 2rem 0;`}
      }
    }
  }
  .car-image {
    margin-top: -10%;
    margin-left: auto;
    max-width: 450px;
    width: 25vw;
    min-width: 120px;
    ${device.small`display: none;`}
  }
  .car-background {
    max-width: 450px;
    width: 25vw;
    min-width: 120px;
    position: absolute !important;
    z-index: -1;
    right: 0;
    bottom: 0;
    filter: blur(3px);
    ${device.small`display: none;`}
  }
`

const Text = styled.div`
  width: 70%;
  margin: 0 auto;
  max-width: 1000px;
  ${device.small`width: var(--spread);`}
  .main-title {
    font-size: 2rem;
    text-align: center;
  }
  p {
    font-size: 1rem;
    margin: 1rem 0;
  }

  a {
    font-size: 1rem;
    color: var(--green);
    text-transform: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2rem 0;
    text-align: center;
  }

  h1 {
    font-size: 1.5rem;
    color: var(--grey);
  }

  h3 {
    font-size: 3rem;
    color: var(--green);
    font-family: "Great Vibes", cursive;
    font-style: normal;
    text-transform: none;
    ${device.small`font-size: 2.5rem;`}
  }

  h2 {
    font-size: 2rem;
    ${device.small`font-size: 1.5rem;`}
  }
`

const Content = ({ data, sectionName, qaData }) => {
  const extLinks = useLinks()
  const firstHeading = data.title.split(" ")[0]

  const imgData = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "subpage/content-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      car: file(relativePath: { eq: "subpage/car.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      carBg: file(relativePath: { eq: "subpage/car-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <OuterWrapper sectionName={sectionName}>
      <Wrapper>
        <div className="left">
          <Text>
            <h1 className="main-title">
              <GreenHeading text={firstHeading} />
              <br />
              {data.title.replace(firstHeading, "")}
            </h1>
            {data.acf.content_1 !== null && (
              <div
                className="first-half"
                dangerouslySetInnerHTML={{
                  __html: transformURLs(
                    data.acf.content_1.replace("&nbsp;", "")
                  ),
                }}
              />
            )}
            {data.acf.content_1 === null && (
              <div
                className="first-half"
                dangerouslySetInnerHTML={{
                  __html: transformURLs(data.content),
                }}
              />
            )}
          </Text>
          <div className="mid-section">
            {data.acf.content_image && data.acf.content_image.localFile && (
              <div className="image-wrapper">
                <Img
                  fluid={data.acf.content_image.localFile.childImageSharp.fluid}
                  alt={data.acf.content_image.alt_text}
                />
                <div className="buttons">
                  <ReserveNow text="Book a ride" />
                  <a href={`tel:${extLinks.toll_free}`} className="call-today">
                    {extLinks.toll_free}
                  </a>
                </div>
              </div>
            )}
            {data.acf.content_image && data.acf.content_image.localFile && (
              <>
                <Img
                  fluid={imgData.car.childImageSharp.fluid}
                  className="car-image"
                />
                <Img
                  fluid={imgData.carBg.childImageSharp.fluid}
                  className="car-background"
                />
              </>
            )}
          </div>
          <Text>
            {data.acf.content_2 && (
              <div
                className="second-half"
                dangerouslySetInnerHTML={{
                  __html: transformURLs(
                    data.acf.content_2.replace("&nbsp;", "")
                  ),
                }}
              />
            )}
            {qaData && (
              <FAQWrapper>
                <FAQHeader>Frequently Asked Questions</FAQHeader>
                {qaData.map(row => {
                  return (
                    <FAQAccordion
                      question={transformURLs(row.question)}
                      answer={transformURLs(row.answer)}
                    />
                  )
                })}
              </FAQWrapper>
            )}
          </Text>
          {data.title === "Reviews" && (
            <>
              <Helmet>
                <script
                  id="reviewScript"
                  src={process.env.GATSBY_BIRDEYE_REVIEW_URL}
                />
              </Helmet>
              <div id={process.env.GATSBY_BIRDEYE_REVIEW_ID} />
            </>
          )}
        </div>
        {sectionName !== "our-services" && (
          <div className="right">
            <Sidebar />
          </div>
        )}
      </Wrapper>
      {sectionName !== "our-services" && (
        <div className="bg">
          <Img fluid={imgData.bg.childImageSharp.fluid} />
        </div>
      )}
    </OuterWrapper>
  )
}

export default Content
