import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import GreenHeading from "../heading"
import device from "../device"

const Wrapper = styled.section`
  width: 300px;
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  ${device.small`margin: 2rem auto; width: var(--spread);`}
  ${device.large`margin-top: 6rem;`}
  h2 {
    margin: 0 auto;
    width: 60%;
    font-size: 1.6rem;
    line-height: 0.9;
    ${device.small`font-size: 1.5rem; width: 50%; font-size: 2rem;`}
    .secondary-heading {
      margin-left: 2rem;
    }
  }
`

const Services = styled.div`
  margin: 2rem auto;
  .category-wrapper {
    position: relative;
    padding: 0.5rem 0;
    background: var(--green);
    a {
      text-transform: none;
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.5;
      }
    }
    &:nth-child(even) {
      background: rgb(240, 240, 240);
      a {
        color: var(--black);
      }
      .cat-img {
        filter: var(--black-filter);
      }
    }
  }
  .category {
    width: 60%;
    margin: 0 auto;
    font-family: "Theano Didot";
    display: flex;
    align-items: center;
    ${device.small`width: 50%;`}
    .cat-img {
      width: 35px;
      filter: var(--white-filter);
    }
    .name {
      margin-left: 2rem;
    }
  }
  .plus {
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--black);
    color: var(--white);
    position: absolute;
    right: calc(0.25rem + 10px);
    top: calc(0.25rem + 10px);
  }
`

const DropDown = styled.div`
  margin: 1rem 0;
  .sub-category {
    display: block;
    text-align: center;
    font-size: 0.8rem;
    padding-top: 0.5rem;
    margin: 0rem 2rem 0.5rem 2rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`

const Sidebar = () => {
  const [isDropdownActive, setDropdownActive] = useState(null)

  function handleClick(e) {
    const currIndex = e.currentTarget.dataset.index
    isDropdownActive === currIndex
      ? setDropdownActive(null)
      : setDropdownActive(currIndex)
  }

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: {
          parent_element: { parent_element: { title: { eq: "Our Services" } } }
        }
      ) {
        edges {
          node {
            title
            slug
            parent_element {
              title
            }
          }
        }
      }
      allWordpressWpServicesCategories {
        edges {
          node {
            name
            slug
            acf {
              image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 35) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  function findChildren(title) {
    const filteredData = data.allWordpressPage.edges
      .filter(({ node }) => {
        return node.parent_element.title === title
      })
      .map(({ node }) => ({
        title: node.title,
        slug: node.slug,
      }))
    return filteredData
  }

  return (
    <Wrapper>
      <h2>
        <GreenHeading size="4rem" text="Our" />
        <br />
        <span className="secondary-heading">Services</span>
      </h2>
      <Services>
        {data.allWordpressWpServicesCategories.edges.map(({ node }, index) => (
          <div className="category-wrapper" key={index}>
            <div className="category">
              <Img
                className="cat-img"
                fluid={node.acf.image.localFile.childImageSharp.fluid}
                alt={node.acf.image.alt_text}
              />
              <Link className="name" to={"/our-services/" + node.slug}>
                {node.name}
              </Link>
            </div>

            {!!findChildren(node.name).length && (
              <span className="plus" data-index={index} onClick={handleClick}>
                {parseInt(isDropdownActive) === index ? "-" : "+"}
              </span>
            )}

            {parseInt(isDropdownActive) === index && (
              <DropDown>
                <div
                  className={`subwrapper ${
                    parseInt(isDropdownActive) === index ? "active" : ""
                  }`}
                >
                  {findChildren(node.name).map(({ title, slug }, i) => (
                    <Link
                      className="sub-category"
                      key={i}
                      to={"/our-services/" + node.slug + "/" + slug}
                    >
                      {title}
                    </Link>
                  ))}
                </div>
              </DropDown>
            )}
          </div>
        ))}
      </Services>
    </Wrapper>
  )
}

export default Sidebar
